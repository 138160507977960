import { Card, MenuItem, Modal, Icon, IconButton, TextField, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import DataTable from "components/DataTable";
// import useFetchRequest from "hooks/useFetchRequest";
import { useCallback, useEffect, useMemo, useState } from "react";
import numeral from "numeral";
import MDButton from "components/MDButton";
import fetchRequest from "utils/fetchRequest";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import StrategySelectionSidebar from "pages/Dataflow/components/StrategySelectionSidebar";
import moment from 'moment';
import { useAppController } from "context";
import { useImmer } from "use-immer";
import { normalizeCurrency } from "utils/table";
import { backgroundProcessCheck } from 'utils'
import Rule from "./component/Rule";

const AddCostPoolRule = (props) => {
  const { typeFilter, yearFilter, monthFilter, setRefresh, chargeBackMonthly, edit, editValue } = props;
  const [mappingType, setMappingType] = useState(typeFilter === "Spend" ? edit ? 'Any Attribute' : undefined : 'Account, Expense Type, Cost Center & Vendor')
  const [ condition, setCondition ] = useState(edit && editValue ? editValue['filterCondition'] : null)
  const chargeBack = _.find(chargeBackMonthly, { monthNameId: monthFilter, yearNameId: yearFilter }) ? true : false

  let mappingTypes = []
  if (typeFilter === "Spend") {
    mappingTypes = [
      { value: 2, displayName: "Account, Expense Type" },
      { value: 3, displayName: "Account, Expense Type & Cost Center" },
      { value: 4, displayName: "Account, Expense Type, Cost Center & Vendor" },
      { value: 1, displayName: "Vendor" },
      { value: 5, displayName: "Any Attribute" },
    ]
  }
  else {
    mappingTypes = [
      { value: 4, displayName: "Account, Expense Type, Cost Center & Vendor" },
    ]
  }


  const selectedMappingTypeOption = mappingTypes?.find(o => o.displayName === mappingType);

  const handleChangeMappingType = (value) => {
    setMappingType(value);
  }

  const handleClear = () => {
    setCondition(null)
  }

  const strategyItemStyles = ({ palette: { white, info } }) => ({
    display: "flex",
    flexDirection: "column",
    px: 2,
    py: 1.5,
    m: 0.8,
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #ddd",
    "& .title": {
      marginBottom: 1
    },
    "&:hover": {
      backgroundColor: info.main
    },
    "&:hover .title, &:hover .subtitle": {
      color: white.main,
    }
  });
  if (typeFilter === "Spend")
    return (
      <>
        {
          !mappingType && (
            <MDBox height="100%" px={3} pt={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <MDTypography variant="subtitle1" fontWeight="light" color="text" component="span" mb={3} mt={props.mt}>
                Choose a mapping strategy
              </MDTypography>
              <MDBox display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" px={3} >
                {
                  mappingTypes?.map((option) => {
                    return <MDBox key={`l_${option.value}`}
                      sx={(theme) => strategyItemStyles(theme)}
                      onClick={() => {
                        setMappingType(option.displayName)
                      }}
                    >
                      <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
                      <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{option.displayName}</MDTypography>
                    </MDBox>
                  })
                }
              </MDBox>
            </MDBox>
          )
        }
        {
          (mappingType && mappingType === 'Any Attribute') && <MappingCondition {...props} yearFilter={yearFilter} monthFilter={monthFilter} mappingTypes={mappingTypes} chargeBack={chargeBack} mappingType={mappingType} onChangeMappingType={handleChangeMappingType} selectedMappingTypeOption={selectedMappingTypeOption} typeFilter={typeFilter} setRefresh={setRefresh} condition={condition} setCondition={setCondition} handleClear={handleClear} edit={edit}  />
        }
        {
          (mappingType && mappingType !== 'Any Attribute') && <FetchData {...props} mappingTypes={mappingTypes} chargeBack={chargeBack} mappingType={mappingType} onChangeMappingType={handleChangeMappingType} selectedMappingTypeOption={selectedMappingTypeOption} typeFilter={typeFilter} setRefresh={setRefresh} />
        }
      </>
    )
  else
    return (
      <>
        {
          mappingType !== undefined && <FetchData yearFilter={yearFilter} chargeBack={chargeBack} monthFilter={monthFilter} mappingTypes={mappingTypes} mappingType={'Account, Expense Type, Cost Center & Vendor'} typeFilter={typeFilter} setRefresh={setRefresh} />
        }
      </>
    )
}

const MappingCondition = (props) => {
  const { condition, setCondition, handleClear, handleChangeMappingType, selectedMappingTypeOption, typeFilter, setRefresh, yearFilter, monthFilter, mappingTypes, mappingType, onChangeMappingType, edit, editValue, setEdit, chargeBack } = props
  const [showOptions, setShowOptions] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [costPool, setCostPool] = useState(edit && editValue ? editValue['costPool'] : "")
  const [ loading, setLoading ] = useState(false)
  const [data, setData] = useState(null)
  const [ rows, setRows ] = useState([])
  const [controller,] = useAppController();
  const [ reload, setReload ] = useState(false)
  const { showSnackbar } = useYADialog();
  const [tablesCols, setTablesCols] = useImmer({});
  const { appDef: { settings } } = controller;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [glRows, setGLRows] = useState([]);
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
  
  const handleDialogOpen = () => {
    setReload(Math.random())
    setRows([])
    setDialogOpen(false)
  }

  const handleOnOptionsClose = () => {
    setShowOptions(false);
  }

  const handleOnOptionsClick = () => {
    setShowOptions(true);
  }

  const getDetails = async (e, row) => {
    if (rows.length > 0 && e.target.innerHTML !== "") {
      const obj = row.original;
      row.original.mappingType = mappingType;
      row.original.year = yearFilter;
      row.original.month = monthFilter;
      row.original.mapping = 'costpool';
      row.original.condition = JSON.parse(condition)?.map( (con, i) => {
        con['operator'] = con['operator']!=='notSet' ? "eq" : con['operator']
        con['k'] = i+1
        if (con["value"]) {
          if(!con["value"].includes(obj[con['field']]??obj[`${con['tableName']}__${con['field']}`]))
            con["value"].push(obj[con['field']]??obj[`${con['tableName']}__${con['field']}`]) 
        } else {
          con["value"] = [obj[con['field']]??obj[`${con['tableName']}__${con['field']}`]]
        }
        return con
      })
      let [err, data] = edit && editValue ? await fetchRequest.post(`/api/dataflow/mapping/getGLData/?edit=${edit}&&ruleId=${editValue['mappingId']}`, JSON.stringify(row.original)) : 
        await fetchRequest.post(`/api/dataflow/mapping/getGLData/`, JSON.stringify(row.original));
      if (err) {
        console.error('err', err)
      }
      else {
        let newData
        newData = data.map(item => {
          return {
            "accountCode": item["account.code"],
            "accountDescription": item["account.description"],
            "expenseType": item["expenseType.name"],
            "costCentreCode": item["costCentre.code"],
            "costCentreDescription": item["costCentre.description"],
            "vendorCode": item["vendor.code"],
            "vendorName": item["vendor.name"],
            "rgtModel": item["rgtModel"],
            "costType": item["costType"],
            "amount": item["amount"],
            "applicationID": item["applicationID"],
            "invoice": item["invoice"],
            "journalID": item["journalID"],
            "journalLine": item["journalLine"],
            "projectID": item["projectID"],
            "transactionDate": item["transactionDate"]
          }
        });
        setDialogOpen(true)
        setGLRows(newData)
      }
    }
  };

  useEffect(async () => {
    if (condition) {
      setLoading(true)
      let [error, data] = edit && editValue ? await fetchRequest.post(`/api/dataflow/costPoolRules3/${yearFilter}/${monthFilter}?edit=${edit}&&ruleId=${editValue['mappingId']}`, condition) : 
      await fetchRequest.post(`/api/dataflow/costPoolRules3/${yearFilter}/${monthFilter}`, condition)
      if (error) {
        if (error.data?.message) {
          showSnackbar(error.data?.message, "error")
        } else {
          showSnackbar("An error occured while processing your request.", "error");
        }
        setLoading(false)
      }
      else if (data) {
        let newData
        newData = data.costElements.map(item => {
          let obj = {}
          Object.keys(item).map( key => {
            let modKey = key.replace(/\./g, "__")
            obj[modKey] = item[key]
          })
          return obj
        });
        setRows(newData)
        setData(data)
        setLoading(false)
      } 
    }
  },[reload])

  const filteredMappingTypes = mappingTypes.filter(m => m.displayName !== mappingType);
  const amountAllocated = rows.reduce((total, idx) => total + idx.amount, 0)??0

  let columns = []
  if (tablesCols && rows.length > 0  && !dialogOpen) {
    Object.keys(rows[0]).map( key => {
      if (key.split('__').length > 1) {
        let tableName = key.split('__')[0]
        let field = key.split('__')[1]
        let displayName = tablesCols[tableName] ? tablesCols[tableName].find(o => o.schemaName === field).displayName : field
        let cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> }
        let column = {
          Header: displayName,
          accessor: key,
          Cell: cell
        }
        columns.push(column)
      } else if (key !== 'amount' && tablesCols['expenditure']) {
        let foundDef = tablesCols['expenditure'].find(o => o.schemaName === key)
        let displayName = foundDef.displayName??key
        let type = foundDef.type
        let cell = null
        if (type === "date") {
          cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> }
        } else {
          cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> }
        }
        let column = {
          Header: displayName,
          accessor: key,
          Cell: cell
        }
        columns.push(column)
      }
    })
    columns.push({
      Header: "Amount",
      accessor: 'amount',
      Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> }
    })
  }

  let glColumns = [
    { Header: "Expense Type", accessor: "expenseType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Account Code", accessor: "accountCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Account Description", accessor: "accountDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Center Code", accessor: "costCentreCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Center Name", accessor: "costCentreDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Vendor Code", accessor: "vendorCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Vendor Name", accessor: "vendorName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "RGT Model", accessor: "rgtModel", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Type", accessor: "costType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> } },
    { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Journal ID", accessor: "journalID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Journal Line", accessor: "journalLine", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Transaction Date", accessor: "transactionDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> } },
    { Header: "Invoice Number", accessor: "invoice", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
  ]

  const strategyItemStyles = () => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    pl: 2,
    pr: 1,
    py: 1.5,
    zIndex: 2,
    marginBottom: "0px",
    marginRight: "-10px",
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #ddd",
    "& .selectionBox": {
      display: "flex",
      flexDirection: "column",
    },
    "& .title": {
      marginBottom: 1
    }
  });

  const handleOnOptionSelection = (value) => {
    if (onChangeMappingType)
      setRows([])
      setEdit(false)
      setCondition(null)
      onChangeMappingType(value);
  }

  const handleConditionClear = () => {
    setRows([])
    handleClear()
  }

  return(
    <>
    <Modal open={dialogOpen} onClose={handleDialogOpen}>
      <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
          <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
              <MDTypography variant="h6" component="span" color="text">
                General Ledger Transactions
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <IconButton onClick={handleDialogOpen} title="Close">
                <Icon>close</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
          <DataTable
            variant="tile"
            table={{ columns: glColumns, rows: glRows }}
            containerMaxHeight={424}
            showTotalEntries={true}
            isSorted={true}
            newStyle1={true}
            noEndBorder
            entriesPerPage={true}
            canSearch={true}
          >
          </DataTable>
        </Card>
      </MDBox>
    </Modal>
    <MDBox display="flex">
    <MDBox width={condition ? "60%" : "100%"} borderRight="1px solid rgba(0, 0, 0, 0.05)">
      <MDBox pl={3} pr={4} pt={1} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="subtitle1" fontWeight="medium" color="dark" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">Create a mapping rule.</MDTypography>
        { !condition &&
          <MDBox
            sx={(theme) => strategyItemStyles(theme)}
            onClick={handleOnOptionsClick}
          >
            <MDBox className="selectionBox">
              <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
              <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{selectedMappingTypeOption?.displayName}</MDTypography>
            </MDBox>
            <Icon sx={{ ml: 1.5, mt: .5, fontSize: "32px!important", color: "rgba(0, 0, 0, 0.54)" }}>keyboard_arrow_down</Icon>
          </MDBox>
        }
      </MDBox>
      <Rule
          multipleRules={true}
          fieldDef={{
              name: "rule",
              displayName: "Rule",
              disableFilters: true,
              required: true,
              unique: true,
              dataSource: {
                  type: "custom",
                  list: [
                    {value: "expenditure", displayName: "Expenditure"},
                    {value: "expenseType", displayName: "Expense Type"},
                    {value: "account", displayName: "Account"},
                    {value: "costCentre", displayName: "Cost Center"},
                    {value: "vendor", displayName: "Vendor"},
                  ]
              }
          }}
          mappingType={"Cost Pool"}
          condition={condition}
          onConditionChange={setCondition}
          setReload={setReload}
          yearFilter={yearFilter}
          monthFilter={monthFilter}
          setRows={setRows}
          onClear={handleConditionClear}
          setTablesCols={setTablesCols} 
          tablesCols={tablesCols}
          edit={edit}
          chargeBack={chargeBack}
      />
      {
        condition &&
        <DataTable
          variant="tile"
          table={{ columns, rows: rows }}
          containerMaxHeight={"calc(100vh - 550px)"}
          showTotalEntries={true}
          isSorted={true}
          newStyle1={true}
          noEndBorder
          entriesPerPage={true}
          canSearch={true}
          loading={loading}
          onRowClick={getDetails}
        />
      }
    </MDBox>
    {
      condition && !loading &&
      <CPRuleSelection 
        {...props} 
        isSubmitting={isSubmitting} 
        setIsSubmitting={setIsSubmitting} 
        errors={errors} 
        setErrors={setErrors} 
        costPool={costPool} 
        setCostPool={setCostPool} 
        onChangeMappingType={handleChangeMappingType} 
        selectedMappingTypeOption={selectedMappingTypeOption} 
        typeFilter={typeFilter} 
        setRefresh={setRefresh} 
        condition={condition}
        setReload={setReload}
        data={data}
        setCondition={setCondition}
        amountAllocated={amountAllocated}
        edit={edit}
      />
    }
    {showOptions && (
      <StrategySelectionSidebar
        options={filteredMappingTypes}
        onOptionSelection={handleOnOptionSelection}
        onOptionsClose={handleOnOptionsClose}
        type={'mapping'}
      />
    )}
    </MDBox>
  </>
  )
}



const CPRuleSelection = (props) => {
  const { yearFilter, monthFilter, condition, setIsSubmitting, isSubmitting, setReload, setErrors, costPool, setCostPool, errors, data, setCondition, amountAllocated, edit, editValue, setEdit } = props
  const { showSnackbar, showAlert } = useYADialog();
  const saveRules = async (evt) => {
    evt.preventDefault();
    let bgData = await backgroundProcessCheck(monthFilter, yearFilter);
    if (bgData.length > 0) {
      showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);

    }
    else {
      let err = false;
      let e = {};
      if (costPool === "") {
        e.costPool = true;
        err = true;
      }
      if (!err) {
        let cpRule = [{
          "id": null,
          "filterCondition": condition,
          "costPoolId": costPool.split(" | ")[0],
          "subCostPoolId": costPool.split(" | ")[1],
          "yearNameId": yearFilter,
          "monthNameId": monthFilter,
          "source": 'GL'
        }]
        setIsSubmitting(true);
        let [error, data1] = edit && editValue ? await fetchRequest.post(`/api/dataflow/costPoolRules/${yearFilter}/${monthFilter}/${editValue['mappingId']}`, JSON.stringify(cpRule)) : await fetchRequest.post(`/api/dataflow/costPoolRules/${yearFilter}/${monthFilter}`, JSON.stringify(cpRule))
        if (error) {
          if (error.data?.message) {
            showSnackbar(error.data?.message, "error")
          } else {
            showSnackbar("An error occured while processing your request.", "error");
          }
        }
        else if (data1) {
          showSnackbar(data1, "success");
          if (props.setRefresh) {
            props.setRefresh(Math.random());
          }
          setReload(Math.random);
          setCondition(null)
          setIsSubmitting(false);
          setEdit(false)
        }
      } else {
        setErrors(e);
        setIsSubmitting(false);
      }
    }
  }

  return(
    <MDBox width="40%" px={3} pt={4} pb={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <MDBox flex={1} textAlign="center" display="flex">
        <MDBox display="flex" flexDirection="column" flex={1} mt="auto">
          <MDTypography variant="button" component="span" fontWeight="medium" color="text">Allocating</MDTypography>
          <MDTypography variant="h3" component="span" fontWeight="medium" color="dark">{numeral(normalizeCurrency(amountAllocated)).format('$0,0')}</MDTypography>
        </MDBox>
        {errors.selectedRows && <MDTypography variant="caption" color="error">Please select an account</MDTypography>}
      </MDBox>
      <MDBox>
        <Icon sx={{ mt: 3, mb: 1, color: "#7b809a", fontSize: "48px!important" }}>south</Icon>
      </MDBox>
      <MDBox flex={1} textAlign="center">
        <MDTypography variant="subtitle2" fontWeight="medium" color={errors?.costPool ? "error" : "dark"}>Choose a Cost Pool *</MDTypography>
        <TextField error={errors.costPool} name="costPool" select margin="normal" variant="outlined" sx={{ minWidth: 300 }} onChange={(e) => { setErrors({}); setCostPool(e.target.value) }} value={costPool} required={true}>
          {data?.subCostPools?.map((item) => <MenuItem key={`${item.costPoolId} | ${item.id}`} value={`${item.costPoolId} | ${item.id}`}>{item["costPool.name"]} | {item["name"]}</MenuItem>)}
        </TextField>
      </MDBox>
      {
        costPool && costPool !== "" && (
          <MDBox px={3} pb={3} display="flex" alignItems="center" justifyContent="center">
            <MDButton name="saveRules" variant="gradient" color="info" sx={{ mt: 2 }}
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined}
              onClick={saveRules}>Save Rules</MDButton>
          </MDBox>
        )
      }
    </MDBox>
  )
}

const FetchData = (props) => {
  const { yearFilter, monthFilter, mappingType, typeFilter, setRefresh, chargeBack, mappingTypes } = props;
  const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });
  const [ loading, setLoading ] = useState(false)
  const [data, setData] = useState(null)
  const [ rows, setRows ] = useState([])
  const [ reload, setReload ] = useState(false)
  const [ _err, setErrors ] = useState(false)
  const [tablesCols, setTablesCols] = useImmer({});
  const [ condition, setCondition ] = useState(null)
  const [ tableVals, setTableVals ] = useState(null)
  const mappingTypeId = mappingTypes.find( o => o.displayName === mappingType).value

  useEffect(async () => {
    let [error, response] = await fetchRequest.get(`/api/dataflow/costPoolStratergy/${mappingTypeId}`)
    if (error) {
      if (error.data?.message) {
        setErrors(error.data?.message)
      } else {
        setErrors("An error occured while processing your request.");
      }
      setLoading(false)
    }
    else if (response) {
      const tables = new Set(response?.map( c => c.tableName))
      setTableVals(tables)
      setCondition(response)
      setLoading(true)
      let [error, data] = typeFilter === "Spend" ? await fetchRequest.post(`/api/dataflow/costPoolRules3/${yearFilter}/${monthFilter}`, response) : await fetchRequest.get(`/api/dataflow/costPoolRulesBudget/${yearFilter}/${monthFilter}?type=${typeFilter.toLowerCase()}`)
      if (error) {
        if (error.data?.message) {
          setErrors(error.data?.message)
        } else {
          setErrors("An error occured while processing your request.");
        }
        setLoading(false)
      }
      else if (data) {
        let newData
        newData = data.costElements.map(item => {
          let obj = {}
          Object.keys(item).map( key => {
            let modKey = key.replace(/\./g, "__")
            obj[modKey] = item[key]
          })
          return obj
        });
        setRows(newData)
        setData(data)
        setLoading(false)
      } 
    }
  },[reload, mappingType])

  useEffect(() => {
    if (tableVals) {
      tableVals.forEach(async tableVal => {
        if (tableVal && tableVal !== "" && !tablesCols[tableVal] ) {
          let url = `/api/dataflow/resource/${tableVal}`
          let [error, response] = await fetchRequest.get(url)
          if (error) {
            console.error(error)
          } else {
            setTablesCols( draft => {
              if (!draft[tableVal]) {
                draft[tableVal] = response.fields
              }
            })
          }
        }
      }) 
    }
  },[tableVals])


  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
      draft.globalFilter = latestGlobalFilter;
      draft.filters = latestFilters;
    });
  }

  if (loading === false && data === null) {
    return (
      <div>
        no data
      </div>
    );
  }
  if (_err)
    console.error(_err)
  return (
    <MDBox>
      {
        loading && <YASkeleton variant="loading" />
      }
      {
        !loading && <ShowData {...props} tablesCols={tablesCols} data={data} rows={rows} condition={condition} setReload={setReload} filtersState={filtersState} chargeBack={chargeBack} handleOnFiltersStateUpdate={handleOnFiltersStateUpdate} typeFilter={typeFilter} setRefresh={setRefresh} />
      }
    </MDBox>
  )
}

const ShowData = (props) => {
  const { data, rows, yearFilter, monthFilter, setReload, condition, mappingType, onChangeMappingType, mappingTypes, selectedMappingTypeOption, filtersState, handleOnFiltersStateUpdate, containerHeight, typeFilter, chargeBack, tablesCols, setEdit } = props;
  const [showOptions, setShowOptions] = useState(false);
  const { showSnackbar, showAlert } = useYADialog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [costPool, setCostPool] = useState("")
  const [errors, setErrors] = useState({});
  const [refresh, setRefresh] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [glRows, setGLRows] = useState([]);
  const filterCondition = condition.filter( c => !c['optional'])
  const [controller,] = useAppController();
  const { appDef: { settings } } = controller;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
  const [clearSelection, setClearSelection] = useState(false);
  const [ columns, setColumns ] = useState([])

  if (typeFilter === "Spend") {
    const handleOnOptionsClick = () => {
      setShowOptions(true);
    }

    const handleOnOptionsClose = () => {
      setShowOptions(false);
    }

    const handleOnOptionSelection = (value) => {
      if (onChangeMappingType)
        setEdit(false)
        onChangeMappingType(value);
    }

    const handleOnUpdate = useCallback(({ selected }) => {
      setSelectedRows(selected)
      setClearSelection(false);
    }, [])

    const handleOnSelectionClearClick = () => {
      setSelectedRows([])
      setClearSelection(true);
      setRefresh(Math.random())
    }

    const handleDialogOpen = () => {
      setDialogOpen(false)
      setReload(Math.random())
    }

    const getDetails = async (e, row) => {
      if (rows.length > 0 && e.target.innerHTML !== "") {
        const obj = row.original;
        row.original.mappingType = mappingType;
        row.original.year = yearFilter;
        row.original.month = monthFilter;
        row.original.mapping = 'costpool';
        row.original.condition = filterCondition.map( (con, i) => {
          con['operator'] = "eq"
          con['k'] = i+1
          if (con["value"]) {
            if(!con["value"].includes(obj[con['field']]??obj[`${con['tableName']}__${con['field']}`]))
              con["value"].push(obj[con['field']]??obj[`${con['tableName']}__${con['field']}`]) 
          } else {
            con["value"] = [obj[con['field']]??obj[`${con['tableName']}__${con['field']}`]]
          }
          return con
        })
        let [err, data] = await fetchRequest.post(`/api/dataflow/mapping/getGLData/`, JSON.stringify(row.original));
        if (err) {
          console.error('err', err)
          // handleError(err);
        }
        else {
          let newData
          newData = data.map(item => {
            return {
              "accountCode": item["account.code"],
              "accountDescription": item["account.description"],
              "expenseType": item["expenseType.name"],
              "costCentreCode": item["costCentre.code"],
              "costCentreDescription": item["costCentre.description"],
              "vendorCode": item["vendor.code"],
              "vendorName": item["vendor.name"],
              "rgtModel": item["rgtModel"],
              "costType": item["costType"],
              "amount": item["amount"],
              "applicationID": item["applicationID"],
              "invoice": item["invoice"],
              "journalID": item["journalID"],
              "journalLine": item["journalLine"],
              "projectID": item["projectID"],
              "transactionDate": item["transactionDate"]
            }
          });
          setGLRows(newData)
          setDialogOpen(true)
        }
      }
    };

    const saveRules = async (evt) => {
      evt.preventDefault();
      let bgData = await backgroundProcessCheck(monthFilter, yearFilter);
      if (bgData.length > 0) {
        showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);
      }
      else {
        let err = false;
        let e = {};
        if (selectedRows.length === 0) {
          e.selectedRows = true;
          err = true;
        }
        if (costPool === "") {
          e.costPool = true;
          err = true;
        }
        if (!err) {
          selectedRows.map((i) => {
            const obj = rows[i];
            filterCondition.forEach( con => {
              con['operator'] = "eq"
              if (con["value"]) {
                if(!con["value"].includes(obj[`${con['tableName']}__${con['field']}`]))
                  con["value"].push(obj[`${con['tableName']}__${con['field']}`]) 
              } else {
                con["value"] = [obj[`${con['tableName']}__${con['field']}`]]
              }
            })
          })
          
          let cpRule = [{
            "id": null,
            "filterCondition": JSON.stringify(filterCondition),
            "costPoolId": costPool.split(" | ")[0],
            "subCostPoolId": costPool.split(" | ")[1],
            "yearNameId": yearFilter,
            "monthNameId": monthFilter,
            "source": 'GL'
          }]
          setIsSubmitting(true);
          let [error, data1] = await fetchRequest.post(`/api/dataflow/costPoolRules/${yearFilter}/${monthFilter}`, JSON.stringify(cpRule))
          if (error) {
            if (error.data?.message) {
              showSnackbar(error.data?.message, "error")
            } else {
              showSnackbar("An error occured while processing your request.", "error");
            }
          }
          else if (data1) {
            showSnackbar(data1, "success");
            if (props.setRefresh) {
              props.setRefresh(Math.random());
            }
            setReload(Math.random());
          }
          setIsSubmitting(false);
        } else {
          setErrors(e);
        }
      }
    }

    const amountAllocated = selectedRows?.reduce((total, idx) => total + rows[idx]?.amount, 0);

    useEffect(() => {
      let columns = []
      if (condition && tablesCols && rows.length > 0 && !dialogOpen) {
        Object.keys(rows[0]).map( key => {
          if (key.split('__').length > 1) {
            let tableName = key.split('__')[0]
            let field = key.split('__')[1]
            let displayName = tablesCols[tableName] ? tablesCols[tableName].find(o => o.schemaName === field).displayName : field
            let cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> }
            let column = {
              Header: displayName,
              accessor: key,
              Cell: cell
            }
            columns.push(column)
          } else if (key !== 'amount' && tablesCols['expenditure']) {
            let foundDef = tablesCols['expenditure'].find(o => o.schemaName === key)
            let displayName = foundDef?.displayName??key
            let type = foundDef?.type
            let cell = null
            if (type === "date") {
              cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> }
            } else {
              cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> }
            }
            let column = {
              Header: displayName,
              accessor: key,
              Cell: cell
            }
            columns.push(column)
          }
        })
        columns.push({
          Header: "Amount",
          accessor: 'amount',
          Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> }
        })
      }
      setColumns(columns)
    },[tablesCols, condition])

    const columnsMemo = useMemo(() => columns, [yearFilter, monthFilter, mappingType, columns, refresh]);
    const rowsMemo = useMemo(() => rows, [yearFilter, monthFilter, mappingType, refresh]);

    const filteredMappingTypes = mappingTypes.filter(m => m.displayName !== mappingType);

    const strategyItemStyles = () => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      pl: 2,
      pr: 1,
      py: 1.5,
      zIndex: 2,
      marginBottom: "0px",
      marginRight: "-10px",
      cursor: "pointer",
      borderRadius: "10px",
      border: "1px solid #ddd",
      "& .selectionBox": {
        display: "flex",
        flexDirection: "column",
      },
      "& .title": {
        marginBottom: 1
      }
    });

    let glColumns = [
      { Header: "Expense Type", accessor: "expenseType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Account Code", accessor: "accountCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Account Description", accessor: "accountDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Center Code", accessor: "costCentreCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Center Name", accessor: "costCentreDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Vendor Code", accessor: "vendorCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Vendor Name", accessor: "vendorName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "RGT Model", accessor: "rgtModel", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Type", accessor: "costType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> } },
      { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Journal ID", accessor: "journalID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Journal Line", accessor: "journalLine", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Transaction Date", accessor: "transactionDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> } },
      { Header: "Invoice Number", accessor: "invoice", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    ]

    if (rows.length > 0) {
      return (
        <>
          <Modal open={dialogOpen} onClose={handleDialogOpen}>
            <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
              <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
                <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <MDBox>
                    <MDTypography variant="h6" component="span" color="text">
                      General Ledger Transactions
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex">
                    <IconButton onClick={handleDialogOpen} title="Close">
                      <Icon>close</Icon>
                    </IconButton>
                  </MDBox>
                </MDBox>
                <DataTable
                  variant="tile"
                  table={{ columns: glColumns, rows: glRows }}
                  containerMaxHeight={424}
                  showTotalEntries={true}
                  isSorted={true}
                  newStyle1={true}
                  noEndBorder
                  entriesPerPage={true}
                  canSearch={true}

                >
                </DataTable>
              </Card>
            </MDBox>
          </Modal>

          <MDBox display="flex">
            <MDBox width={selectedRows?.length > 0 ? "60%" : "100%"} borderRight="1px solid rgba(0, 0, 0, 0.05)">
              {typeFilter !== "Budget" && typeFilter !== "Forecast" &&
                <MDBox pl={3} pr={4} pt={1} display="flex" alignItems="center" justifyContent="space-between">
                  <MDTypography variant="subtitle1" fontWeight="medium" color="dark" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">Select one or more accounts to create a rule.</MDTypography>
                  {
                    selectedRows?.length === 0 &&
                    <MDBox
                      sx={(theme) => strategyItemStyles(theme)}
                      onClick={handleOnOptionsClick}
                    >
                      <MDBox className="selectionBox">
                        <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
                        <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{selectedMappingTypeOption?.displayName}</MDTypography>
                      </MDBox>
                      <Icon sx={{ ml: 1.5, mt: .5, fontSize: "32px!important", color: "rgba(0, 0, 0, 0.54)" }}>keyboard_arrow_down</Icon>
                    </MDBox>
                  }
                </MDBox>
              }
              <DataTable
                variant="tile"
                table={{ columns: columnsMemo, rows: rowsMemo }}
                showTotalEntries={true}
                isSorted={true}
                containerMaxHeight={containerHeight}
                newStyle1={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                isSelectable={!chargeBack}
                onUpdate={handleOnUpdate}
                onRowClick={getDetails}
                onSelectionClearClick={handleOnSelectionClearClick}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                canFilter={true}
                clearSelection={clearSelection}
              >
              </DataTable>
            </MDBox>
            {
              selectedRows?.length > 0 && (
                <MDBox width="40%" px={3} pt={4} pb={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <MDBox flex={1} textAlign="center" display="flex">
                    <MDBox display="flex" flexDirection="column" flex={1} mt="auto">
                      <MDTypography variant="button" component="span" fontWeight="medium" color="text">Allocating</MDTypography>
                      <MDTypography variant="h3" component="span" fontWeight="medium" color="dark">{numeral(normalizeCurrency(amountAllocated)).format('$0,0')}</MDTypography>
                    </MDBox>
                    {errors.selectedRows && <MDTypography variant="caption" color="error">Please select an account</MDTypography>}
                  </MDBox>
                  <MDBox>
                    <Icon sx={{ mt: 3, mb: 1, color: "#7b809a", fontSize: "48px!important" }}>south</Icon>
                  </MDBox>
                  <MDBox flex={1} textAlign="center">
                    <MDTypography variant="subtitle2" fontWeight="medium" color={errors?.costPool ? "error" : "dark"}>Choose a Cost Pool *</MDTypography>
                    <TextField error={errors.costPool} name="costPool" select margin="normal" variant="outlined" sx={{ minWidth: 300 }} onChange={(e) => { setErrors({}); setCostPool(e.target.value) }} value={costPool} required={true}>
                      {data.subCostPools.map((item) => <MenuItem key={`${item.costPoolId} | ${item.id}`} value={`${item.costPoolId} | ${item.id}`}>{item["costPool.name"]} | {item["name"]}</MenuItem>)}
                    </TextField>
                  </MDBox>
                  {
                    costPool && costPool !== "" && (
                      <MDBox px={3} pb={3} display="flex" alignItems="center" justifyContent="center">
                        <MDButton name="saveRules" variant="gradient" color="info" sx={{ mt: 2 }}
                          disabled={isSubmitting}
                          startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined}
                          onClick={saveRules}>Save Rules</MDButton>
                      </MDBox>
                    )
                  }
                </MDBox>
              )
            }
            {showOptions && (
              <StrategySelectionSidebar
                options={filteredMappingTypes}
                onOptionSelection={handleOnOptionSelection}
                onOptionsClose={handleOnOptionsClose}
                type={'mapping'}
              />
            )}
          </MDBox>
        </>
      )
    } else if (rows.length === 0 && data.noOfItems > 0) {
      return (<MDBox height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br /><br />All accounts have been mapped successfully.</MDTypography>
      </MDBox>
      );
    } else if (data.noOfItems === 0) {
      return (<MDBox height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br /><br />Actuals are not loaded.</MDTypography>
      </MDBox>
      );
    }
  }
  else {
    const getDetails = async (e, row) => {
      if (rows.length > 0 && e.target.innerHTML !== "") {
        row.original.mappingType = mappingType;
        row.original.year = yearFilter;
        row.original.month = monthFilter;
        row.original.mapping = 'costpool';
        let [err, data] = await fetchRequest.post(`/api/dataflow/getGLDataBudget/`, JSON.stringify(row.original));
        if (err) {
          console.error('err', err)
          // handleError(err);
        }
        else {
          let newData

          newData = data.map(item => {
            return {
              "account__code": item["account.code"],
              "account__description": item["account.description"],
              "expenseType__name": item["expenseType.name"],
              "costCentre__code": item["costCentre.code"],
              "costCentre__description": item["costCentre.description"],
              "vendor__code": item["vendor.code"],
              "vendor__name": item["vendor.name"],
              "amount": item["amount"]
            }
          });
          setGLRows(newData)
          setDialogOpen(true)
        }
      }
    };

    let columns = []
    if (typeFilter !== 'Spend') {
      columns.push(
        { Header: "Expense Type", accessor: "expenseType__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Account Code", accessor: "account__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Account Description", accessor: "account__description", dataType: "string", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Center Code", accessor: "costCentre__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Center Name", accessor: "costCentre__description", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Vendor Code", accessor: "vendor__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Vendor Name", accessor: "vendor__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: typeFilter, accessor: "amount", align: "right", dataType: "currency", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(value.toFixed(2)).format("$0,0")}</MDTypography> } },
      )
    }
    const columnsMemo = useMemo(() => columns, [yearFilter, monthFilter, refresh, mappingType, ]);
    const rowsMemo = useMemo(() => rows, [yearFilter, monthFilter, mappingType, refresh]);

    // const filteredMappingTypes = mappingTypes.filter(m => m.value !== mappingType);

    // const strategyItemStyles = () => ({
    //   display: "flex",
    //   flexDirection: "row",
    //   alignItems: "center",
    //   pl: 2,
    //   pr: 1,
    //   py: 1.5,
    //   zIndex: 2,
    //   marginBottom: "0px",
    //   marginRight: "-10px",
    //   cursor: "pointer",
    //   borderRadius: "10px",
    //   border: "1px solid #ddd",
    //   "& .selectionBox": {
    //     display: "flex",
    //     flexDirection: "column",
    //   },
    //   "& .title": {
    //     marginBottom: 1
    //   }
    // });

    if (rows.length > 0) {
      return (
        <>

          <MDBox display="flex">
            <MDBox width={selectedRows?.length > 0 ? "60%" : "100%"} borderRight="1px solid rgba(0, 0, 0, 0.05)">
              <DataTable
                variant="tile"
                table={{ columns: columnsMemo, rows: rowsMemo }}
                showTotalEntries={true}
                isSorted={true}
                containerMaxHeight={containerHeight}
                newStyle1={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                isSelectable={false}
                onRowClick={getDetails}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                canFilter={true}
              >
              </DataTable>
            </MDBox>
          </MDBox>
        </>
      )
    } else if (rows.length === 0 && data.noOfItems > 0) {
      return (<MDBox height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br /><br />All accounts have been mapped successfully.</MDTypography>
      </MDBox>
      );
    } else if (data.noOfItems === 0) {
      return (<MDBox height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br /><br />Actuals are not loaded.</MDTypography>
      </MDBox>
      );
    }
  }

}

export default AddCostPoolRule;