import _ from "lodash";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import ListRule from "../ListRule";
import OfferingRule from "../OfferingRule";
import{backgroundProcessCheck} from 'utils'
import { useYADialog } from "components/YADialog";
const SingleTowerRule = (props) => {
  const { enableAssetDistribution, yearFilter, monthFilter, costElementsRemaining, selectedRows, isSubmitting, submitRules, subTowers, mappingType, edit, editValue } = props;
  const [tierAllocation, setTierAllocation] = useState([0, 0, 0, 0, 0]);
  const [towerSingle, setTowerSingle] = useState(edit && editValue ? editValue['tower'] : "");
  const [destinationTable, setDestinationTable] = useState(edit && editValue['destinationTable'] ? editValue['destinationTable'] !== 'Solution Offering' ? editValue['destinationTable'].toLowerCase() : 'solutionOffering' : "");
  const [splitStrategy, setSplitStrategy] = useState(edit && editValue ? editValue['weight'] ? "byWeight" : editValue['tier'] ? 'byTier' : null : null);
  const [weightColumn, setWeightColumn] = useState(edit && editValue['weight'] ? editValue['weight'].toLowerCase() : "");
  const [condition, setCondition] = useState(edit && editValue ? editValue['condition'] : "");
  const [errors, setErrors] = useState({});
  const [distributionType, setDestributionType] = useState(edit && editValue['destinationTable'] ? editValue['destinationTable'] !== 'Solution Offering' ? 'asset' : "offering" : "")
  const [showListField, setShowListField] = useState(edit && editValue ? true : false);
  const { showAlert } = useYADialog();
  const handleClearAssetSelection = () => {
    setErrors({});
    setDestinationTable("");
    setSplitStrategy(null);
    setWeightColumn("");
    setCondition("");
    setTierAllocation([0, 0, 0, 0, 0]);
  }

  const handleClearDistibution = () => {
    setDestributionType("")
    setErrors({});
    setDestinationTable("");
    setSplitStrategy(null);
    setWeightColumn("");
    setCondition("");
    setTierAllocation([0, 0, 0, 0, 0]);
  }

  const handleOnSplitStrategyChange = (strategyValue) => {
    setTierAllocation([0, 0, 0, 0, 0]);
    setWeightColumn("");
    setSplitStrategy(strategyValue)
  }

  const validateData = () => {
    var err = false;
    var e = {};
    if (selectedRows.length === 0 && !mappingType === 3) {
      e.selectedRows = true;
      err = true;
    }
    if (towerSingle === "") {
      e.towerSingle = true;
      err = true;
    }
    if (splitStrategy === "byWeight" && weightColumn === "") {
      e.weightColumn = true;
      err = true;
    }
    if (splitStrategy === "byTier" && _.sum(tierAllocation) !== 100) {
      e.tierAllocation = true;
      err = true;
    }
    if (distributionType && distributionType !== ""  && destinationTable === ""){
      e.destinationTable = true
      err = true;
  }

    return [err, e];
  }

  const saveRules = async (evt) => {
    evt.preventDefault();
    let bgData = await backgroundProcessCheck(monthFilter, yearFilter);
    if (bgData.length > 0) {
      showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);
    }
    else {
      const [err, e] = validateData();
      if (!err) {
        let ceSelected = [];
        if (splitStrategy === "byTier") {
          if (selectedRows.length > 0) {
            selectedRows?.forEach((i) => {
              let srlNo = 1
              tierAllocation?.forEach((trItem, j) => {
                if (trItem && trItem !== 0 && trItem !== "") {
                  ceSelected.push({
                    ...costElementsRemaining[i], "towerId": towerSingle.split(" | ")[0], "subTowerId": towerSingle.split(" | ")[1], "id": null,
                    "yearNameId": yearFilter, "monthNameId": monthFilter, "tier": 'Tier ' + (j + 1), "srlNo": srlNo, "portion": (parseInt(trItem) / 100), "destinationTable": destinationTable, "condition": condition, "uploadedFileId": null, weight: weightColumn
                  })
                  srlNo ++
                }
              })
            });  
          } else {
            let srlNo = 1
            tierAllocation?.forEach((trItem, j) => {
              if (trItem && trItem !== 0 && trItem !== "") {
                ceSelected.push({
                  "towerId": towerSingle.split(" | ")[0], "subTowerId": towerSingle.split(" | ")[1], "id": null,
                  "yearNameId": yearFilter, "monthNameId": monthFilter, "tier": 'Tier ' + (j + 1), "srlNo": srlNo, "portion": (parseInt(trItem) / 100), "destinationTable": destinationTable, "condition": condition, "uploadedFileId": null, weight: weightColumn
                })
                srlNo ++
              }
            })
          }
        }
        else {
          if (selectedRows.length > 0) {
            ceSelected = selectedRows.map((i) => {
              return {
                ...costElementsRemaining[i], "towerId": towerSingle.split(" | ")[0], "subTowerId": towerSingle.split(" | ")[1], "id": null,
                "yearNameId": yearFilter, "monthNameId": monthFilter, "tier": null, "srlNo": 1, "portion": 1, "destinationTable": destinationTable, "condition": condition, "uploadedFileId": null, "weight": weightColumn
              }
            }); 
          } else {
            ceSelected = [{
              "towerId": towerSingle.split(" | ")[0], "subTowerId": towerSingle.split(" | ")[1], "id": null,
              "yearNameId": yearFilter, "monthNameId": monthFilter, "tier": null, "srlNo": 1, "portion": 1, "destinationTable": destinationTable, "condition": condition, "uploadedFileId": null, "weight": weightColumn
            }]
          }
        }

        submitRules(ceSelected);

      } else {
        setErrors(e);
      }
    }
  }

  return (
    <MDBox height="100%" textAlign="center" pb={2} >
      <MDBox height="calc(91vh - 400px)" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ overflowY: "auto" }}>
        <MDTypography variant="subtitle2" fontWeight="medium" color={errors?.towerSingle ? "error" : "dark"}>{towerSingle && towerSingle !== "" ? "Allocating to Tower" : "Choose a Tower *"}</MDTypography>
        <TextField sx={{ minWidth: 300, pt: 0.5, pb: 1 }} name="tower" select margin="dense" variant="outlined"
          onChange={(e) => { setErrors({}); setTowerSingle(e.target.value) }}
          value={towerSingle}
          error={errors?.towerSingle}
        >
          {subTowers.map((item) => <MenuItem key={`${item.towerId} | ${item.id}`} value={`${item.towerId} | ${item.id}`}>{item["tower.name"]} | {item["name"]}</MenuItem>)}
        </TextField>
        {
          enableAssetDistribution && towerSingle && towerSingle !== "" && distributionType === "" && (
            <MDBox display="flex" flexDirection="row">
              <MDButton
                size="small"
                sx={{ mt: 1, ml: 1, textTransform: "none" }}
                variant="outlined"
                color="info"
                onClick={() => { setDestributionType("asset"); setShowListField(true) }}>Distribute to Asset?</MDButton>
              <MDButton
                size="small"
                sx={{ mt: 1, ml: 1, textTransform: "none" }}
                variant="outlined"
                color="info"
                onClick={() => { setDestributionType("offering"); setShowListField(true) }}>Distribute to Offering?</MDButton>
            </MDBox>
          )
        }
        {distributionType === "asset" && (
          <ListRule
            yearFilter={yearFilter}
            monthFilter={monthFilter}
            destinationTable={destinationTable}
            splitStrategy={splitStrategy}
            weightColumn={weightColumn}
            tierAllocation={tierAllocation}
            condition={condition}
            onDestinationTableChange={setDestinationTable}
            onSplitStrategyChange={handleOnSplitStrategyChange}
            onWeightColumnChange={setWeightColumn}
            onTierAllocationChange={setTierAllocation}
            onConditionChange={setCondition}
            onClearAssetSelection={handleClearAssetSelection}
            onResetDistibution={handleClearDistibution}
            errors={errors}
            showListField={showListField}
            setErrors={setErrors}
          />
        )}
        {distributionType === "offering" && (
          <OfferingRule
            yearFilter={yearFilter}
            monthFilter={monthFilter}
            destinationTable={destinationTable}
            splitStrategy={splitStrategy}
            weightColumn={weightColumn}
            tierAllocation={tierAllocation}
            condition={condition}
            onDestinationTableChange={setDestinationTable}
            onSplitStrategyChange={handleOnSplitStrategyChange}
            onWeightColumnChange={setWeightColumn}
            onTierAllocationChange={setTierAllocation}
            onConditionChange={setCondition}
            onClearAssetSelection={handleClearAssetSelection}
            onResetDistibution={handleClearDistibution}
            errors={errors}
            showListField={showListField}
            setErrors={setErrors}
          />
        )}

      </MDBox>
      {
        towerSingle && towerSingle !== "" && (
          <MDBox pt={1} sx={{ backgroundColor: "white" }}>
            <MDButton name="saveRules"
              variant="gradient"
              color="info"
              onClick={saveRules}
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined}
            >
              Save Rules
            </MDButton>
          </MDBox>
        )
      }
    </MDBox>
  );
};

export default SingleTowerRule;